import React from "react"
import MetaTags from "./MetaTags"
import logo from "../images/okeep_circle.png"

const Layout = ({ children }) => {
  return (
    <>
      <MetaTags />
      <div className="flex flex-col min-h-screen antialiased bg-zinc-100">
        <header className="sticky flex items-center justify-between py-4 px-4 md:px-12 shadow-md">
          <a href="/" className="flex items-center hover:bg-purple-100 rounded px-2 py-1">
              <img src={logo} className="h-12" alt="Optikeep logo" />
              <h1 className="font-semibold text-lg ml-4 text-purple-800">Optikeep</h1>
          </a>
          {/* <div>
            <a href="/updates" className="p-3 rounded bg-lime-700 hover:bg-lime-600 text-white text-sm">
              Sign up for updates
            </a>
          </div> */}
        </header>
        {children}
        <footer className="py-6 text-center bg-gradient-to-r from-zinc-800 to-zinc-700 text-zinc-200 text-xs">
          &copy; 2022 Optikeep, LLC
        </footer>
      </div>
    </>
  )
}

export default Layout