import React from "react"
import dashboardsImage from "../images/optikeep_dashboards.svg"
import tasksImage from "../images/tasks_screen.png"

const Main = () => {
    return (
    
    <main className="w-full">
        <div className="bg-gradient-to-t from-purple-700 to-purple-900">
            <div className="relative max-w-5xl mx-auto py-32 px-4">
                <h1 className="text-white font-extrabold text-4xl sm:text-5xl lg:text-6xl tracking-tight text-center">
                    Take control of your home tasks
                </h1>
                <p className="text-slate-200 max-w-3xl mt-6 text-center mx-auto text-xl">
                Whether you are a homeowner, a building owner or both, Optikeep keeps you on track with your plans for 
                improvements, maintenance reminders, quotes and costs.
                </p>
            </div>
        </div>
        <div className="pt-32 bg-gradient-to-b from-zinc-200 to-zinc-100 px-6">
            <div className="max-w-5xl grid md:grid-cols-2 gap-20 mx-auto">
                <div className="lg:pt-12 sm:pt-6 md:order-2">
                    <h1 className="font-bold text-2xl sm:text-3xl lg:text-4xl tracking-tight text-zinc-900">
                        Pesky maintenance doesn't have to be a chore
                    </h1>
                    <p className="mt-8 text-lg text-zinc-800 pr-12">
                        Optikeep will keep things organized and make sure you're on track with your home.
                        It will also track history and guide you to improvements and saving money over time.
                    </p>
                </div>
                <img className="rounded-lg shadow-xl shadow-zinc-400 md:order-1" src={tasksImage} alt="optikeep tasks screenshot" />
            </div>
        </div>
        <div className="pt-32 mx-6">
            <div className="max-w-5xl grid md:grid-cols-2 gap-20 mx-auto">
                <div className="pt-12 sm:pt-6">
                    <h2 className="font-bold text-2xl sm:text-3xl lg:text-4xl tracking-tight text-zinc-900">
                        Get things done, wherever you are
                    </h2>
                    <p className="mt-8 text-lg text-zinc-800 pr-12">
                        Review your dashboard on the computer, or have it with you on your phone. Attach photos to your task so you can refer to it later, or track conditions over time.
                    </p>
                </div>
                <img src={dashboardsImage} alt="optikeep mobile and web dashboards" />
            </div>
        </div>
        <div className="mt-32 bg-gradient-to-br from-emerald-900 to-emerald-600">
            <div className="relative max-w-5xl mx-auto py-32 px-4">
                <h2 className="text-white text-center font-extrabold tracking-tight text-3xl sm:text-4xl lg:text-5xl">
                    We're getting things ready
                </h2>
                <p className="text-zinc-200 text-center text-xl mt-12">
                    Our beta is coming soon, and we're excited to share it with you.
                </p>
            </div>
        </div>
      </main>
    )
}

export default Main